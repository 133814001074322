<script lang="ts" setup>
defineEmits<{
    (e: 'click:close', id: string | number): void;
}>();

withDefaults(
    defineProps<{
        color: string;
        closeTarget?: string | number;
    }>(),
    {
        closeTarget: '',
    },
);

const slots = useSlots();
</script>

<template>
    <!-- don't remove; enforce unocss to include dynamically used classes: class="border-brand-info border-brand-success border-brand-warning border-brand-danger" -->
    <div
        :class="`border-${color}`"
        class="flex w-full bg-white rounded-md shadow p-5 border-l-8"
        role="alert"
    >
        <div class="flex flex-col w-full">
            <div
                v-if="slots.heading"
                class="mb-2 heading-6"
            >
                <slot name="heading" />
            </div>

            <slot name="message" />
        </div>

        <div>
            <button
                v-if="closeTarget"
                aria-label="Close"
                class="rounded-lg p-1.5 bg-transparent"
                type="button"
                @click="$emit('click:close', closeTarget)"
            >
                <span class="sr-only">Close</span>
                <LazySvgoXMarkCircle class="text-gray-500 h-5 w-auto" />
            </button>
        </div>
    </div>
</template>
